<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <!------------------------------------STEPPER-------------------------------------->
    <div>
      <v-row class="mb-0 d-flex justify-center align-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="pb-0 mb-0"
        >
          <v-card class="white px-auto pb-0 mb-0">
            <v-stepper>
              <v-stepper-header>
                <v-stepper-step
                  step="1"
                  complete
                  complete-icon="mdi-home-circle"
                >
                  RENTAL INFO
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  step="2"
                  complete
                  complete-icon="mdi-home-circle"
                >
                  CHOOSE PLAN
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">
                  PROPERTY INFO
                </v-stepper-step>

                <v-divider></v-divider>
                <v-stepper-step step="4">
                  COVERAGES
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="5">
                  PURCHASE PLAN
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="6">
                  DOWNLOAD YOUR POLICY
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-card>
        </v-col>
      </v-row>
      <!-- LOGIN -->
      <div v-if="!userpresent && !loading">
        <v-row class="justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
          >
            <v-card>
              <v-card-text>
                <v-row class="d-flex justify-center">
                  <v-col
                    class="d-flex justify-center text-h3 text-center primary--text mb-6 mt-6"
                  >
                    In order to continue with the application please log in or
                    create an account.
                  </v-col>
                </v-row>
                <v-row>
                  {{ error }}
                </v-row>
                <v-row>
                  <v-col>
                    <v-form ref="form">
                      <validation-observer
                        ref="observer"
                        v-slot="{ invalid }"
                      >
                        <v-row class="d-flex justify-center">
                          <v-col class="pb-0">
                            <validation-provider
                              v-slot="{ errors }"
                              name="Email"
                              rules="required|email"
                            >
                              <v-text-field
                                v-model="user.email"
                                label="Email"
                                outlined
                                append-icon="mdi-email-outline"
                                :error-messages="errors"
                                @click="
                                  clickedFieldGlobal(
                                    'selectPlan user.email',
                                    35,
                                    'Renters Survey v1'
                                  )
                                "
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center">
                          <v-col class="pb-0">
                            <validation-provider
                              v-slot="{ errors }"
                              vid="password"
                              name="Password"
                              rules="required"
                            >
                              <v-text-field
                                ref="password"
                                v-model="user.password"
                                name="password"
                                label="Password"
                                outlined
                                :type="show ? 'text' : 'password'"
                                :append-icon="
                                  show ? 'mdi-eye-outline' : 'mdi-eye-off'
                                "
                                :error-messages="errors"
                                @click:append="
                                  show = !show;
                                  clickedFieldGlobal(
                                    'selectPlan user.password',
                                    36,
                                    'Renters Survey v1'
                                  );
                                "
                              >
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center">
                          <v-col
                            cols="6"
                            class="d-flex justify-center"
                          >
                            <v-btn
                              class="primary whtie--text mr-0"
                              block
                              large
                              :disabled="invalid"
                              @keyup.enter="submit"
                              @click="
                                loginSubmit();
                                clickedFieldGlobal(
                                  'selectPlan logIn',
                                  37,
                                  'Renters Survey v1'
                                );
                              "
                            >
                              <span class="login-btn">Log In</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </validation-observer>
                    </v-form>
                    <v-row class="d-flex justify-center">
                      <v-col>
                        <div class="signup-text text-center">
                          <span
                            style="color: #757575;"
                          >Don't have an account?</span>
                          <button
                            text
                            class="primary--text font-weight-bold"
                            @click="
                              createAccount();
                              clickedFieldGlobal(
                                'selectPlan createAccount',
                                38,
                                'Renters Survey v1'
                              );
                            "
                          >
                            Create One For Me using: {{ renter.email }}
                          </button>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!-----------------------------------------TITLE-------------------------------------->
      <v-row class="mt-6">
        <v-col class="text-center">
          <div class="title-text">
            Choose A Plan That’s Right For You
          </div>
        </v-col>
      </v-row>
      <!--------------------------------------PLAN CARDS------------------------------------>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="text-center"
        >
          <h2>
            These rates are preliminary quotes to give you an idea of what your
            policy will cost. You can modify coverage options and apply eligible
            discounts on the next page. To continue, select the quote based on
            your preferred deductible.
          </h2>
          <br />
          <p
            v-if="quoteWarning"
            class="font-weight-bold red--text"
          >
            {{ quoteWarning }}
          </p>
        </v-col>
      </v-row>
      <div class="hidden-xs-only">
        <v-row class="d-flex justify-center pb-16">
          <v-col
            cols="12"
            sm="12"
            md="9"
            class="d-flex justify-space-between"
          >
            <!---------------------CARD1----------------------->
            <v-card
              v-model="premium"
              class="pa-4 travel--text"
              width="32%"
              @click="
                clickedFieldGlobal(
                  'selectPlan premiumCard',
                  39,
                  'Renters Survey v1'
                )
              "
            >
              <div class="plan-title text-center font-weight-bold">
                ${{ smallQuotePremium }}/yr
              </div>
              <div class="plan-sub text-center font-weight-bold">
                Payment Plans Available
              </div>
              <v-divider class="my-2 travel"></v-divider>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    ${{ smallQuoteDeductible }}
                    {{ smallQuoteHurricaneDeductible ? "All Other" : "" }}
                    {{
                      quoteWarning && !smallQuoteHurricaneDeductible
                        ? "Excluding wind and hail"
                        : "Deductible"
                    }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="smallQuoteHurricaneDeductible"
                    class="font-weight-bold"
                  >
                    ${{ smallQuoteHurricaneDeductible }} Hurricane Deductible
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    This is what you will pay out of pocket if you have a claim
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ smallQuotePPR }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Personal Property Replacement Cost
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ smallQuoteLiability }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Liability
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-btn
                    class="travel white--text font-weight-bold"
                    :disabled="!userpresent"
                    @click="
                      setPlan(plans[0].name, smallQuoteNumber, smallQuote);
                      clickedFieldGlobal(
                        'selectPlan selectPremiumCard',
                        40,
                        'Renters Survey v1'
                      );
                    "
                  >
                    SELECT
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <!---------------------CARD2----------------------->
            <v-card
              v-model="preferred"
              class="pa-4 primary--text"
              width="32%"
              @click="
                clickedFieldGlobal(
                  'selectPlan preferredCard',
                  41,
                  'Renters Survey v1'
                )
              "
            >
              <div class="plan-title text-center font-weight-bold">
                ${{ mediumQuotePremium }}/yr
              </div>
              <div class="plan-sub font-weight-bold text-center">
                Payment Plans Available
              </div>
              <v-divider class="my-2 primary"></v-divider>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    ${{ mediumQuoteDeductible }}
                    {{ mediumQuoteHurricaneDeductible ? "All Other" : "" }}
                    {{
                      quoteWarning ? "Excluding wind and hail" : "Deductible"
                    }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="mediumQuoteHurricaneDeductible"
                    class="font-weight-bold"
                  >
                    ${{ mediumQuoteHurricaneDeductible }} Hurricane Deductible
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    This is what you will pay out of pocket if you have a claim
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ mediumQuotePPR }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Personal Property Replacement Cost
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ mediumQuoteLiability }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Liability
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-btn
                    class="primary white--text font-weight-bold"
                    :disabled="!userpresent"
                    @click="
                      setPlan(plans[1].name, mediumQuoteNumber, mediumQuote);
                      clickedFieldGlobal(
                        'selectPlan selectPreferredCard',
                        42,
                        'Renters Survey v1'
                      );
                    "
                  >
                    SELECT
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <!---------------------CARD3----------------------->
            <v-card
              v-model="standard"
              class="pa-4 secondary--text"
              width="32%"
              @click="
                clickedFieldGlobal(
                  'selectPlan standardCard',
                  43,
                  'Renters Survey v1'
                )
              "
            >
              <div class="plan-title text-center font-weight-bold">
                ${{ largeQuotePremium }}/yr
              </div>
              <div class="plan-sub font-weight-bold text-center">
                Payment Plans Available
              </div>
              <v-divider class="my-2 secondary"></v-divider>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    ${{ largeQuoteDeductible }}
                    {{ largeQuoteHurricaneDeductible ? "All Other" : "" }}
                    {{
                      quoteWarning ? "Excluding wind and hail" : "Deductible"
                    }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="largeQuoteHurricaneDeductible"
                    class="font-weight-bold"
                  >
                    ${{ largeQuoteHurricaneDeductible }} Hurricane Deductible
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    This is what you will pay out of pocket if you have a claim
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ largeQuotePPR }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Personal Property Replacement Cost
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    $ {{ largeQuoteLiability }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Liability
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-btn
                    class="secondary white--text font-weight-bold"
                    :disabled="!userpresent"
                    @click="
                      setPlan(plans[2].name, largeQuoteNumber, largeQuote);
                      clickedFieldGlobal(
                        'selectPlan selectStandardCard',
                        44,
                        'Renters Survey v1'
                      );
                    "
                  >
                    SELECT
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-----------------------------------------------Mobile---------------------------------------->
      <div
        v-if="mobileDevice"
        class="mx-0 px-0"
      >
        <v-slide-group class="mx-0 px-0">
          <v-card
            v-model="premium"
            class="pa-4 travel--text mr-2"
            width="75vw"
            @click="
              clickedFieldGlobal(
                'selectPlan premiumCardMobile',
                45,
                'Renters Survey v1'
              )
            "
          >
            <div class="plan-title text-center font-weight-bold">
              ${{ smallQuotePremium }}/yr
            </div>
            <div class="plan-sub text-center font-weight-bold">
              Payment Plans Available
            </div>
            <v-divider class="my-2 travel"></v-divider>
            <v-list-item three-line>
              <v-list-item-content>
                <p class="font-weight-bold">
                  ${{ smallQuoteDeductible }}
                  {{ smallQuoteHurricaneDeductible ? "All Other" : "" }}
                  {{
                    quoteWarning && !smallQuoteHurricaneDeductible
                      ? "Excluding wind and hail"
                      : "Deductible"
                  }}
                </p>
                <v-list-item-title
                  v-if="smallQuoteHurricaneDeductible"
                  class="font-weight-bold"
                >
                  ${{ smallQuoteHurricaneDeductible }} Hurricane Deductible
                </v-list-item-title>
                <v-list-item-subtitle>
                  This is what you will pay out of pocket if you have a claim
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ smallQuotePPR }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Personal Property Replacement Cost
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ smallQuoteLiability }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Liability
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  class="travel white--text font-weight-bold"
                  :disabled="!userpresent"
                  @click="
                    setPlan(plans[0].name, smallQuoteNumber, smallQuote);
                    clickedFieldGlobal(
                      'selectPlan selectPremiumCardMobile',
                      46,
                      'Renters Survey v1'
                    );
                  "
                >
                  SELECT
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            v-model="preferred"
            class="pa-4 primary--text mx-2"
            width="75vw"
            @click="
              clickedFieldGlobal(
                'selectPlan preferredCardMobile',
                47,
                'Renters Survey v1'
              )
            "
          >
            <div class="plan-title text-center font-weight-bold">
              ${{ mediumQuotePremium }}/yr
            </div>
            <div class="plan-sub font-weight-bold text-center">
              Payment Plans Available
            </div>
            <v-divider class="my-2 primary"></v-divider>
            <v-list-item three-line>
              <v-list-item-content>
                <p class="font-weight-bold">
                  ${{ mediumQuoteDeductible }}
                  {{ mediumQuoteHurricaneDeductible ? "All Other" : "" }}
                  {{ quoteWarning ? "Excluding wind and hail" : "Deductible" }}
                </p>
                <v-list-item-title
                  v-if="mediumQuoteHurricaneDeductible"
                  class="font-weight-bold"
                >
                  ${{ mediumQuoteHurricaneDeductible }} Hurricane Deductible
                </v-list-item-title>
                <v-list-item-subtitle>
                  This is what you will pay out of pocket if you have a claim
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ mediumQuotePPR }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Personal Property Replacement Cost
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ mediumQuoteLiability }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Liability
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  class="primary white--text font-weight-bold"
                  :disabled="!userpresent"
                  @click="
                    setPlan(plans[1].name, mediumQuoteNumber, mediumQuote);
                    clickedFieldGlobal(
                      'selectPlan selectPreferredCardMobile',
                      48,
                      'Renters Survey v1'
                    );
                  "
                >
                  SELECT
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            v-model="standard"
            class="pa-4 secondary--text ml-2"
            width="75vw"
            @click="
              clickedFieldGlobal(
                'selectPlan standardCardMobile',
                49,
                'Renters Survey v1'
              )
            "
          >
            <div class="plan-title text-center font-weight-bold">
              ${{ largeQuotePremium }}/yr
            </div>
            <div class="plan-sub font-weight-bold text-center">
              Payment Plans Available
            </div>
            <v-divider class="my-2 secondary"></v-divider>
            <v-list-item three-line>
              <v-list-item-content>
                <p class="font-weight-bold">
                  ${{ largeQuoteDeductible }}
                  {{ largeQuoteHurricaneDeductible ? "All Other" : "" }}
                  {{ quoteWarning ? "Excluding wind and hail" : "Deductible" }}
                </p>
                <v-list-item-title
                  v-if="largeQuoteHurricaneDeductible"
                  class="font-weight-bold"
                >
                  ${{ largeQuoteHurricaneDeductible }} Hurricane Deductible
                </v-list-item-title>
                <v-list-item-subtitle>
                  This is what you will pay out of pocket if you have a claim
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ largeQuotePPR }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Personal Property Replacement Cost
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  $ {{ largeQuoteLiability }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Liability
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  class="secondary white--text font-weight-bold"
                  :disabled="!userpresent"
                  @click="
                    setPlan(plans[2].name, largeQuoteNumber, largeQuote);
                    clickedFieldGlobal(
                      'selectPlan selectStandardCardMobile',
                      50,
                      'Renters Survey v1'
                    );
                  "
                >
                  SELECT
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-col> </v-col>
        </v-slide-group>
      </div>
    </div>
    <v-dialog
      v-model="errorDialog"
      max-width="700"
      persistent
    >
      <v-card class="pa-2">
        <v-card-title class="justify-center font-weight-bold text-h5">
          We are unable to process your request
        </v-card-title>
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col>
              We’re sorry that we are unable to process your application at this time due to the following error:
            </v-col>
          </v-row>
          <v-row>
            <v-col class="font-italic">
              {{ error }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row
            class="d-flex justify-center"
            no-gutters
          >
            <v-col class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                to="/renters/info"
                @click="clearError"
              >
                Restart Application
              </v-btn>
              <v-btn
                color="primary"
                class="mb-4 mt-4 mr-0 font-weight-bold"
                to="/"
                @click="clearError"
              >
                Return to Home
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        <v-row
          no-gutters
          class="d-flex justify-center font-weight-bold text-h5"
        >
          <v-col class="d-flex justify-center font-weight-bold text-center">
            Please contact us at 1-(888)-333-3145 for support with your application.
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  var numeral = require('numeral')
  export default {
    data () {
      return {
        show: '',
        user: {
          email: '',
          password: '',
        },
        errorDialog: false,
        standard: '',
        premium: '',
        preferred: '',
        selectedPlan: '',
        plans: [
          {
            name: 'Premium',
            price: '$1,697.00/yr',
          },
          {
            name: 'Preferred',
            price: '$1,839.00/yr',
          },
          {
            name: 'Standard',
            price: '$2,100.00/yr',
          },
        ],
      }
    },
    computed: {
      quoteWarning () {
        return this.$store.getters.getRentersQuoteWarning
      },
      loading () {
        return this.$store.getters.getloading
      },
      userpresent () {
        let confirmedUser = false
        console.log(
          'confirmed user ',
          confirmedUser,
          this.$store.getters.getuser
        )
        if (this.$store.getters.getuser !== null) {
          confirmedUser = true
        }
        console.log('confirmed user ', confirmedUser)
        return confirmedUser
      },
      renter () {
        return this.$store.getters.getRenter
      },
      error () {
        return this.$store.getters.error
      },
      smallQuoteHurricaneDeductible () {
        return this.getHurricaneDeductible(this.smallQuote)
      },
      mediumQuoteHurricaneDeductible () {
        return this.getHurricaneDeductible(this.mediumQuote)
      },
      largeQuoteHurricaneDeductible () {
        return this.getHurricaneDeductible(this.largeQuote)
      },
      smallQuoteNumber () {
        return this.$store.getters.getSmallRentalQuoteNumber
      },
      mediumQuoteNumber () {
        return this.$store.getters.getMediumRentalQuoteNumber
      },
      largeQuoteNumber () {
        return this.$store.getters.getLargeRentalQuoteNumber
      },
      smallQuoteDeductible () {
        return numeral(
          this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0]
            .FormatInteger[0]
        ).format('0.00')
      },
      mediumQuoteDeductible () {
        return numeral(
          this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0]
            .FormatInteger[0]
        ).format('0.00')
      },
      largeQuoteDeductible () {
        return numeral(
          this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0]
            .FormatInteger[0]
        ).format('0.00')
      },
      smallQuotePPR () {
        return numeral(
          this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      mediumQuotePPR () {
        return numeral(
          this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      largeQuotePPR () {
        return numeral(
          this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      smallQuoteLiability () {
        return numeral(
          this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[2].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      mediumQuoteLiability () {
        return numeral(
          this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[2].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      largeQuoteLiability () {
        return numeral(
          this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[2].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      smallQuotePremium () {
        return numeral(
          this.smallQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        ).format('0.00')
      },
      mediumQuotePremium () {
        return numeral(
          this.mediumQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        ).format('0.00')
      },
      largeQuotePremium () {
        return numeral(
          this.largeQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        ).format('0.00')
      },
      smallQuote () {
        return this.$store.getters.getSmallRentalQuote
      },
      mediumQuote () {
        return this.$store.getters.getMediumRentalQuote
      },
      largeQuote () {
        return this.$store.getters.getLargeRentalQuote
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      premiumSelected () {
        return this.selectedPlan === 'Premium'
      },
      preferredSelected () {
        return this.selectedPlan === 'Preferred'
      },
      standardSelected () {
        return this.selectedPlan === 'Standard'
      },
    },
    watch: {
      error (newValue) {
        console.log('error changed:', newValue)
        this.errorDialog = !!newValue
      }
    },
    created () {
      console.log(this.largeQuote)
      console.log(this.mediumQuote)
      console.log(this.smallQuote)
      this.user.email = this.renter.email
    },
    methods: {
      getHurricaneDeductible (quote) {
        const defaultDeductible = ''

        const dwell =
        quote?.ACORD?.InsuranceSvcRs[0]?.HomePolicyQuoteInqRs[0]
          ?.HomeLineBusiness[0]?.Dwell[0]

        if (!dwell?.Coverage) {
          return defaultDeductible
        }

        const hurrCoverage = dwell.Coverage.find(
          (coverage) => coverage.CoverageCd[0] === 'HURR'
        )

        if (!hurrCoverage || !hurrCoverage.Deductible) {
          return defaultDeductible
        }

        const hurrDeductible = hurrCoverage.Deductible.find(
          (deductible) => deductible.DeductibleAppliesToCd[0] === 'HURR'
        )

        if (!hurrDeductible) {
          return defaultDeductible
        }

        return numeral(hurrDeductible.FormatInteger[0]).format('0.00')
      },
      createAccount () {
        // put account creation function here
        console.log('creating account with ', this.renter.email)
        this.$store.dispatch('createUserAndProfileForUser', {
          email: this.renter.email,
        })
      },
      loginSubmit () {
        // log user in
        console.log('login with ', this.user.email, this.user.password)
        this.$store.dispatch('signUserIn', {
          email: this.user.email,
          password: this.user.password,
        })
      },
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
      },
      setPlan (plan, quoteNumber, quote) {
        this.selectedPlan = plan
        let renter = Object.assign(this.$store.getters.getRenter, {
          quoteNumber: quoteNumber,
          quote: quote,
        })
        console.log(renter)
        // this.$store.dispatch('rentersPreBind', renter)
        console.log('setting rental quote', quote)
        this.$store.dispatch('setRentalQuote', quote)
        this.$router.push({ path: '/renters/propertyinfo' })
      },
    },
  }
</script>

<style lang="scss" scoped>
.green-background {
  background-color: #d8fdd7;
}
.title-text {
  font-size: 36px;
  font-weight: 400;
  color: #00a1b7;
}
.plan-title {
  font-size: 33px;
}
.plan-sub {
  font-size: 18px;
}
.selected {
  background-color: #005f6c;
  color: white;
}
.white {
  color: white;
}

.center {
  justify-content: center;
}
.left {
  justify-content: start;
}
.end {
  justify-content: end;
}
</style>
